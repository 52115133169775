/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { List, ListItem, withStyles } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button.jsx";
import { Link } from "gatsby";
import TermsModal from "../TermsModal/TermsModal.jsx";
import ReturnsModal from "../ReturnsModal/ReturnsModal.jsx";
import PrivacyModal from "../PrivacyModal/PrivacyModal.jsx";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "../../assets/jss/material-components/footerStyle.jsx";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          {/* <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link to="/">
                <Button
                  className={classes.footerbuttons}
                  color="transparent"
                  block
                >
                  Home
                </Button>
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link to="/about">
                <Button
                  className={classes.footerbuttons}
                  color="transparent"
                  block
                >
                  About us
                </Button>
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link to="/contact">
                <Button
                  className={classes.footerbuttons}
                  color="transparent"
                  block
                >
                  Contact Us
                </Button>
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <ReturnsModal />
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <PrivacyModal />
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <TermsModal />
              {/* <a
                  href="/"
                  className={classes.block}
                  target="_blank"
                >
                  Terms &amp; Conditions
                </a>
            </ListItem>
          </List>  */}
        </div>
        <div className={classes.right}>
          <div>
            &copy; {1900 + new Date().getYear()} ,{" "}
            <a className={aClasses} href="https://cainppe.com/" target="_blank">
              CAIN
            </a>
            , a{" "}
            <a
              href="https://culrosssolutions.com/"
              className={aClasses}
              target="_blank"
            >
              Culross Solutions brand
            </a>{" "}
          </div>
          <div>
            {" "}
            Website by{" "}
            <a
              href="https://thepixelconnoisseur.com/"
              className={aClasses}
              target="_blank"
            >
              The Pixel Connoisseur
            </a>{" "}
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
