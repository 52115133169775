import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Link } from "gatsby";

import Close from "@material-ui/icons/Close";

// core components

import Button from "../../components/CustomButtons/Button.jsx";
import javascriptStyles from "../../assets/jss/material-components/views/javascriptStyles.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class PrivacyModal extends React.Component {
  anchorElLeft = null;
  anchorElTop = null;
  anchorElBottom = null;
  anchorElRight = null;
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false,
      openLeft: false,
      openTop: false,
      openBottom: false,
      openRight: false,
    };
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClosePopover(state) {
    this.setState({
      [state]: false,
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.termscontainer}>
        <Button
          color="transparent"
          block
          onClick={() => this.handleClickOpen("classicModal")}
        >
          Privacy Policy
        </Button>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.classicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("classicModal")}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose("classicModal")}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            <h4 className={classes.modalTitle}>Privacy Policy</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <p>
              At Culross Management Consultants we are committed to safeguarding
              and preserving the privacy of our visitors. This Privacy Policy
              explains what happens to any personal data that you provide to us,
              or that we collect from you whilst you visit our site.
            </p>
            <p>
              We do update this Policy from time to time so please do review
              this Policy regularly.
            </p>

            <h5 className={classes.modalpara}>Information We Collect</h5>

            <p>
              In running and maintaining our website we may collect and process
              the following data about you:
            </p>

            <p>
              i. Information about your use of our site including details of
              your visits such as pages viewed and the resources that you
              access. Such information includes traffic data, location data and
              other communication data.
            </p>

            <p>
              ii. Information provided voluntarily by you. For example, when you
              register for information or make a purchase.
            </p>

            <p>
              iii. Information that you provide when you communicate with us by
              any means.
            </p>

            <h5 className={classes.modalpara}>Use of Cookies</h5>

            <p>
              Cookies provide information regarding the computer used by a
              visitor. We may use cookies where appropriate to gather
              information about your computer in order to assist us in improving
              our website.
            </p>
            <p>
              We may gather information about your general internet use by using
              the cookie. Where used, these cookies are downloaded to your
              computer and stored on the computer’s hard drive. Such information
              will not identify you personally. It is statistical data. This
              statistical data does not identify any personal details
              whatsoever.
            </p>
            <p>
              You can adjust the settings on your computer to decline any
              cookies if you wish. This can easily be done by activating the
              reject cookies setting on your computer.
            </p>
            <p>
              Our advertisers may also use cookies, over which we have no
              control. Such cookies (if used) would be downloaded once you click
              on advertisements on our website.
            </p>

            <h5 className={classes.modalpara}>Use of Your Information</h5>

            <p>
              We use the information that we collect from you to provide our
              services to you. In addition to this we may use the information
              for one or more of the following purposes:
            </p>

            <p>
              i. To provide information to you that you request from us relating
              to our products or services.
            </p>
            <p>
              ii. To provide information to you relating to other products that
              may be of interest to you. Such additional information will only
              be provided where you have consented to receive such information.
            </p>
            <p>
              iii. To inform you of any changes to our website, services or
              goods and products.
            </p>
            <p>
              If you have previously purchased goods or services from us we may
              provide to you details of similar goods or services, or other
              goods and services, that you may be interested in.
            </p>
            <p>
              Where your consent has been provided in advance we may allow
              selected third parties to use your data to enable them to provide
              you with information regarding unrelated goods and services which
              we believe may interest you. Where such consent has been provided
              it can be withdrawn by you at any time.
            </p>

            <h5 className={classes.modalpara}>Storing Your Personal Data</h5>

            <p>
              In operating our website it may become necessary to transfer data
              that we collect from you to locations outside of the European
              Union for processing and storing. By providing your personal data
              to us, you agree to this transfer, storing or processing. We do
              our upmost to ensure that all reasonable steps are taken to make
              sure that your data is treated stored securely.
            </p>
            <p>
              Unfortunately the sending of information via the internet is not
              totally secure and on occasion such information can be
              intercepted. We cannot guarantee the security of data that you
              choose to send us electronically. Sending such information is
              entirely at your own risk.
            </p>

            <p>Shipping costs are nonrefundable.</p>

            <h5 className={classes.modalpara}>Disclosing Your Information</h5>

            <p>
              We will not disclose your personal information to any other party
              other than in accordance with this Privacy Policy and in the
              circumstances detailed below:
            </p>
            <p>
              i. In the event that we sell any or all of our business to the
              buyer.
            </p>
            <p>
              ii. Where we are legally required by law to disclose your personal
              information.
            </p>
            <p>
              iii. To further fraud protection and reduce the risk of fraud.
            </p>

            <h5 className={classes.modalpara}>Third Party Links</h5>

            <p>
              On occasion we include links to third parties on this website.
              Where we provide a link it does not mean that we endorse or
              approve that site’s policy towards visitor privacy. You should
              review their privacy policy before sending them any personal data.
            </p>

            <h5 className={classes.modalpara}>Access to Information</h5>

            <p>
              In accordance with the Data Protection Act 1998 you have the right
              to access any information that we hold relating to you. Please
              note that we reserve the right to charge a fee of £10 to cover
              costs incurred by us in providing you with the information.
            </p>

            <h5 className={classes.modalpara}>Contacting Us</h5>

            <p>
              Please do not hesitate to contact us regarding any matter relating
              to this Privacy Policy at info@culrosssolutions.com
            </p>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Link to="/contact">
              <Button
                color="transparent"
                simple
                onClick={() => this.handleClose("classicModal")}
              >
                Contact Us
              </Button>
            </Link>
            <Button
              onClick={() => this.handleClose("classicModal")}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(javascriptStyles)(PrivacyModal);
