import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Link } from "gatsby";

import Close from "@material-ui/icons/Close";

// core components

import Button from "../../components/CustomButtons/Button.jsx";
import javascriptStyles from "../../assets/jss/material-components/views/javascriptStyles.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class TermsModal extends React.Component {
  anchorElLeft = null;
  anchorElTop = null;
  anchorElBottom = null;
  anchorElRight = null;
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false,
      openLeft: false,
      openTop: false,
      openBottom: false,
      openRight: false,
    };
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClosePopover(state) {
    this.setState({
      [state]: false,
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.termscontainer}>
        <Button
          color="transparent"
          block
          onClick={() => this.handleClickOpen("classicModal")}
        >
          Terms &amp; Conditions
        </Button>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.classicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("classicModal")}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose("classicModal")}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            <h4 className={classes.modalTitle}>Terms &amp; Conditions</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <h5 className={classes.modalpara}>GENERAL</h5>
            <h5 className={classes.modalpara}>1. ACCEPTANCE OF TERMS</h5>
            <p>
              Culross Management Consultants Ltd T/A Culross Solutions is
              Registered in England and Wales, company number 12030860.
            </p>
            <p>
              Your access to and use of Culross Solutions (“the Website”) is
              subject exclusively to all these Terms and Conditions. You will
              not use the Website for any purpose that is unlawful or prohibited
              by these Terms and Conditions. By using the Website you are fully
              accepting the terms, conditions and disclaimers contained in this
              notice. If you do not accept these Terms and Conditions you must
              immediately stop using the Website.
            </p>

            <h5 className={classes.modalpara}>2. ADVICE</h5>
            <p>
              The contents of the Website do not constitute advice and should
              not be relied upon in making or refraining from making, any
              decision.
            </p>

            <h5 className={classes.modalpara}>3. CHANGES TO WEBSITE</h5>
            <p>
              Culross Management Consultants Ltd T/A Culross Solutions reserves
              the right to:
            </p>
            <p>
              3.1 Change or remove (temporarily or permanently) the Website or
              any part of it without notice and you confirm that Precise Time
              Ltd T/A Precise Time Watch Repairs shall not be liable to you for
              any such change or removal.
            </p>
            <p>
              3.2 Change these Terms and Conditions at any time, and your
              continued use of the Website following any changes shall be deemed
              to be your acceptance of such change.
            </p>

            <h5 className={classes.modalpara}>
              4. LINKS TO THIRD PARTY WEBSITES
            </h5>

            <p>
              The Website may include links to third party Websites that are
              controlled and maintained by others. Any link to other websites is
              not an endorsement of such websites and you acknowledge and agree
              that we are not responsible for the content or availability of any
              such sites.
            </p>

            <h5 className={classes.modalpara}>5. COPYRIGHT</h5>
            <p>
              5.1 All copyright, trade marks and all other intellectual property
              rights in the Website and its content (including without
              limitation the Website design, text, graphics and all software and
              source codes connected with the Website) are owned by or licensed
              to Culross Management Consultants Ltd T/A Culross Solutions or
              otherwise used by Culross Management Consultants Ltd T/A Culross
              Solutions as permitted by law.
            </p>
            <p>
              5.2 In accessing the Website you agree that you will access the
              content solely for your personal, non-commercial use. None of the
              content may be downloaded, copied, reproduced, transmitted,
              stored, sold or distributed without the prior written consent of
              the relevant copyright holder. This excludes the downloading,
              copying and/or printing of pages of the Website for personal,
              non-commercial home use only.
            </p>

            <h5 className={classes.modalpara}>
              6. DISCLAIMERS AND LIMITATION OF LIABILITY
            </h5>
            <p>
              6.1 The Website is provided on an “AS IS” and “AS AVAILABLE” basis
              without any representation or endorsement made and without
              warranty of any kind whether express or implied, including but not
              limited to the implied warranties of satisfactory quality, fitness
              for a particular purpose, non-infringement, compatibility,
              security and accuracy.
            </p>
            <p>
              6.2 To the extent permitted by law, Culross Management Consultants
              Ltd T/A Culross Solutions will not be liable for any indirect or
              consequential loss or damage whatever (including without
              limitation loss of business, opportunity, data, profits) arising
              out of or in connection with the use of the Website.
            </p>
            <p>
              6.3 Culross Management Consultants Ltd T/A Culross Solutions makes
              no warranty that the functionality of the Website will be
              uninterrupted or error free, that defects will be corrected or
              that the Website or our dedicated server that makes it available
              are free of viruses or anything else which may be harmful or
              destructive.
            </p>
            <p>
              6.4 Nothing in these Terms and Conditions shall be construed so as
              to exclude or limit the liability of Culross Management
              Consultants Ltd T/A Culross Solutions for death or personal injury
              as a result from any type of negligence of Culross Management
              Consultants Ltd T/A Culross Solutions or that of its employees or
              agents.
            </p>

            <h5 className={classes.modalpara}>7. INDEMNITY</h5>
            <p>
              You agree to indemnify and Culross Management Consultants Ltd T/A
              Culross Solutions and its employees and agents harmless from and
              against all liabilities, legal fees, damages, losses, costs and
              other expenses in relation to any claims or actions brought
              against Culross Management Consultants Ltd T/A Culross Solutions
              arising out of any breach by you of these Terms and Conditions or
              other liabilities arising out of your use of this Website.
            </p>

            <h5 className={classes.modalpara}>8. SEVERANCE</h5>
            <p>
              If any of these Terms and Conditions should be determined to be
              invalid, illegal or unenforceable for any reason by any court of
              competent jurisdiction then such Term or Condition shall be
              severed and the remaining Terms and Conditions shall survive and
              remain in full force and effect and continue to be binding and
              enforceable.
            </p>

            <h5 className={classes.modalpara}>9. GOVERNING LAW</h5>
            <p>
              These Terms and Conditions shall be governed by and construed in
              accordance with the law of England and you hereby submit to the
              exclusive jurisdiction of the English courts.
            </p>

            <h5 className={classes.modalpara}>10. PRICE AND PAYMENT</h5>
            <p>
              If, by human error or electronic glitch we have under priced one
              or more of our items for general sale within our webstore, we will
              not be liable to sell that item to you at the stated price,
              provided that we notify you before we dispatch the item. In those
              circumstances, we will advise the correct price so that you can
              make an informed decision about whether you wish to proceed the
              order at the correct price. The email confirmation of payment and
              receipt of order you receive does not constitute a contract for us
              to supply goods, but is simply an acknowledgement of receipt of
              payment and proposed order as governed under English Law. Our
              contract to supply your goods begins when we dispatch your order.
            </p>
            <p>
              10.1 Any price that is stipulated on the Website is a guide only.
              The price will show VAT separately.
            </p>
            <p>10.2 The price excludes delivery charges.</p>
            <p>
              10.3 Payment of the price plus delivery charges must be made in
              full before dispatch of any goods.
            </p>

            <h5 className={classes.modalpara}>11. RESTRICTED ACCESS</h5>
            <p>
              11.1 Access to certain areas of our website is restricted. We
              reserve the right to restrict access to other areas of our
              website, or indeed our whole website, at our discretion.
            </p>
            <p>
              11.2 If we provide you with a user ID and password to enable you
              to access restricted areas of our website or other content or
              services, you must ensure that that user ID and password is kept
              confidential.
            </p>
            <p>
              11.3 We may disable your user ID and password in our sole
              discretion without notice or explanation.
            </p>

            <h5 className={classes.modalpara}>12. LIMITATION OF LIABILITY</h5>
            <p>
              Except as may be implied by law where the Buyer is dealing as a
              Consumer, in the event of any breach of these Terms and Conditions
              by Culross Management Consultants Ltd T/A Culross Solutions the
              remedies of the Buyer shall be limited to damages which shall in
              no circumstances exceed the Price of the Goods and Culross
              Management Consultants Ltd T/A Culross Solutions shall under no
              circumstances be liable for any indirect, incidental or
              consequential loss or damage whatever. Nothing in these terms of
              use or elsewhere on our website will exclude or limit our
              liability for fraud, for death or personal injury caused by our
              negligence, or for any other liability which cannot be excluded or
              limited under applicable law. Subject to this, our liability to
              you in relation to the use of our website or under or in
              connection with these terms of use, whether in contract, tort
              including negligence or otherwise, will be limited as follows:
            </p>
            <p>
              12.1 To the extent that the website and the information and
              services on the website are provided free-of-charge, we will not
              be liable for any loss or damage of any nature.
            </p>

            <p>
              12.2 We will not be liable for any consequential, indirect or
              special loss or damage.
            </p>

            <p>
              12.3 We will not be liable for any loss of profit, income,
              revenue, anticipated savings, contracts, business, goodwill,
              reputation, data, or information.
            </p>

            <p>
              12.4 We will not be liable for any loss or damage arising out of
              any event or events beyond our reasonable control.
            </p>

            <p>
              12.5 Our maximum liability in relation to any event or series of
              related events will be limited to £1,000 and under no
              circumstances shall exceed the Price of the Goods.
            </p>

            <h5 className={classes.modalpara}>13. WAIVER</h5>

            <p>
              No waiver by Culross Management Consultants Ltd T/A Culross
              Solutions (whether express or implied) in enforcing any of its
              rights under this contract shall prejudice its rights to do so in
              the future.
            </p>

            <h5 className={classes.modalpara}>
              14. EXCLUSIONS OF THIRD PARTY RIGHTS
            </h5>
            <p>
              These terms of use are for the benefit of you and us, and are not
              intended to benefit any third party or be enforceable by any third
              party. The exercise of our and your rights in relation to these
              terms of use is not subject to the consent of any third party.
            </p>

            <h5 className={classes.modalpara}>15. FORCE MAJEURE</h5>
            <p>
              Culross Management Consultants Ltd T/A Culross Solutions shall not
              be liable for any delay or failure to perform any of its
              obligations if the delay or failure results from events or
              circumstances outside its reasonable control, including but not
              limited to acts of God, strikes, lock outs, accidents, war, fire,
              failure of any communications, telecommunications or computer
              system, breakdown of plant or machinery or shortage or
              unavailability of raw materials and or parts from a natural source
              of supply, and Culross Management Consultants Ltd T/A Culross
              Solutions shall be entitled to a reasonable extension of its
              obligations.
            </p>

            <h5 className={classes.modalpara}>
              16. CHANGES TO TERMS AND CONDITIONS AND PRIVACY POLICY
            </h5>
            <p>
              Culross Management Consultants Ltd T/A Culross Solutions shall be
              entitled to alter these Terms and Conditions at any time but this
              right shall not affect the existing Terms and Conditions accepted
              by the Buyer upon making a purchase.
            </p>

            <h5 className={classes.modalpara}>
              17. REGISTRATIONS AND AUTHORISATIONS
            </h5>
            <p>
              We are registered in the United Kingdom with Companies House. You
              can find the on-line version of the register at
              www.companieshouse.gov.uk
            </p>
            <p>Our registration number is 12030860</p>

            <h5 className={classes.modalpara}>18. OUR DETAILS</h5>
            <p>
              The full name of our company is Culross Management Consultants Ltd
              T/A Culross Solutions{" "}
            </p>
            <p>
              We are registered in England &amp; Wales under registration number
              12030860.
            </p>

            <h5 className={classes.modalpara}>19. STATUTORY RIGHTS</h5>
            <p>
              Nothing in our terms and conditions affects your statutory rights.
              Any dispute regarding any transactions will be dealt with under
              United Kingdom law.
            </p>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Link to="/contact">
              <Button
                color="transparent"
                simple
                onClick={() => this.handleClose("classicModal")}
              >
                Contact Us
              </Button>
            </Link>
            <Button
              onClick={() => this.handleClose("classicModal")}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(javascriptStyles)(TermsModal);
