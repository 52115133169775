import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Link } from "gatsby";

import Close from "@material-ui/icons/Close";

// core components

import Button from "../../components/CustomButtons/Button.jsx";
import javascriptStyles from "../../assets/jss/material-components/views/javascriptStyles.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ReturnsModal extends React.Component {
  anchorElLeft = null;
  anchorElTop = null;
  anchorElBottom = null;
  anchorElRight = null;
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false,
      openLeft: false,
      openTop: false,
      openBottom: false,
      openRight: false,
    };
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClosePopover(state) {
    this.setState({
      [state]: false,
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.termscontainer}>
        <Button
          color="transparent"
          block
          onClick={() => this.handleClickOpen("classicModal")}
        >
          Returns
        </Button>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.classicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("classicModal")}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose("classicModal")}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            <h4 className={classes.modalTitle}>Returns &amp; Refund Policy</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <p>Thanks for purchasing from Culross Ltd.</p>
            <p>
              If you are not entirely satisfied with your purchase, we're here
              to help.
            </p>

            <h5 className={classes.modalpara}>Returns</h5>

            <p>
              You have 30 calendar days to return an item from the date you
              received it.{" "}
            </p>

            <p>
              To be eligible for a return, your item must be unused and in the
              same condition that you received it.{" "}
            </p>

            <p>
              You must inform us via email that you will return the product{" "}
            </p>

            <p>Your item must be in the original packaging. </p>

            <p>Your item needs to have the receipt or proof of purchase. </p>

            <h5 className={classes.modalpara}>Refunds</h5>

            <p>
              Once we receive your item, we will inspect it and notify you that
              we have received your returned item. We will immediately notify
              you on the status of your refund after inspecting the item.
            </p>
            <p>
              If your return is approved, we will initiate a refund to your
              credit card (or original method of payment).
            </p>
            <p>
              You will receive the credit within a certain amount of days,
              depending on your card issuer's policies.
            </p>

            <h5 className={classes.modalpara}>Shipping</h5>

            <p>
              You will be responsible for paying for your own shipping costs for
              returning your item.
            </p>

            <p>Shipping costs are nonrefundable.</p>

            <h5 className={classes.modalpara}>Contact Us</h5>

            <p>
              If you have any questions on how to return your item to us,
              contact us.
            </p>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Link to="/contact">
              <Button
                color="transparent"
                simple
                onClick={() => this.handleClose("classicModal")}
              >
                Contact Us
              </Button>
            </Link>
            <Button
              onClick={() => this.handleClose("classicModal")}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(javascriptStyles)(ReturnsModal);
