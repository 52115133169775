import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import "../assets/scss/material-kit-react.scss?v=1.4.0";
import "typeface-montserrat";

import ContextProvider from "~/provider/ContextProvider";

// import { GlobalStyle } from "~/utils/styles";
import Navigation from "~/components/Navigation";

// import Headermain from "../components/Header/Headermain.jsx";
import Footer from "../components/Footer/Footer.jsx";

// const Wrapper = styled.div`
//   margin: 0 auto;
//   max-width: 960px;
//   padding: 0px 1.0875rem 1.45rem;
// `;

const Layout = ({ children }) => {
  return (
    <ContextProvider>
      {/* <GlobalStyle /> */}
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <>
            {/* <Headermain /> */}
            {/* <Navigation siteTitle={data.site.siteMetadata.title} /> */}
            {/* <Wrapper> */}
            {children}
            {/* <footer>
                © {new Date().getFullYear()}, Built with
                {` `}
                <a href="https://www.gatsbyjs.org">Gatsby</a>
              </footer> */}
            <Footer />
            {/* </Wrapper> */}
          </>
        )}
      />
    </ContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
