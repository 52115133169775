import { container, primaryColor } from "../material-kit-react.jsx";

const footerStyle = {
  block: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    color: "#e6e6e6",
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important",
    color: "#e6e6e6",
  },
  footer: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    padding: "0.9375rem 0",
    textAlign: "center",
    zIndex: "2",
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    background: "#040707",
  },
  a: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    color: "#e6e6e6",
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    color: "#e6e6e6",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  footerbuttons: {
    color: "#e6e6e6",
  }
};
export default footerStyle;
