import React from "react";
import Image from "gatsby-image";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
};

export const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      body {
        margin: 0;
      }
      html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }
    `}
  />
);

export const Img = styled(Image)`
  max-width: 100 %;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
`;

export const Lback = styled.div`
  text-align: left;
  font-size: 0.7rem;
  opacity: 0.75;
`;

export const Svgstyle = styled.span`
  svg {
    top: 0;
    width: 18px;
    height: 18px;
    font-size: 2rem;
    display: inline-block;
    position: relative;
    margin-right: 4px;
    vertical-align: middle;
  }
`;

export const CartContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 960px;
  margin-top: 15rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const CartPad = styled.div`
  margin-bottom: 15rem;
`;

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: "left . right";
  margin-bottom: 2rem;

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`;

export const GridLeft = styled.div`
  grid-area: left;
`;

export const GridRight = styled.div`
  grid-area: right;
`;

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

export const CartTitle = styled.div`
  border-bottom: 4px solid black;
`;
